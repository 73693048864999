/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react'
import { useRouter } from 'next/router'
import { jsx, Box } from 'theme-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import ListMenu from '/components/Commons/ListMenu'
import tracking from '/lib/tracking'
import getTrackingInfo from '/utils/getTrackingInfo'

import styles from './styles'

export const NavMobile = ({ navItems }) => {
  const [openList, setOpenList] = useState(false)
  const { sendCSMEvent } = tracking()
  const router = useRouter()
  const locale = router?.query?.locale

  const onClickMenu = (e) => {
    e.stopPropagation()
    setOpenList(true)

    const trackDetail =
      router.route === '/[locale]/partnership' ? 'mNavFom' : 'mNavHome'
    sendCSMEvent(getTrackingInfo('mobileNav', trackDetail), locale)
  }

  return (
    <nav>
      <Box sx={styles.navMobilecontainer} onClick={onClickMenu}>
        <span sx={{ ...styles.linktitle, variant: `text.menuLinktitle` }}>
          {navItems?.title}
        </span>
        <FontAwesomeIcon
          icon={faBars}
          sx={{
            variant: 'icons.menuBar',
          }}
        />
      </Box>
      <ListMenu
        onClose={() => setOpenList(!openList)}
        opened={openList}
        navItems={navItems?.menu?.links}
      />
    </nav>
  )
}
