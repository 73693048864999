/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react'
import { jsx } from 'theme-ui'

import { TRACKING_ACTION, TRACKING_CATEGORY } from '/constants/siteTagging'
import getTrackingInfo from '/utils/getTrackingInfo'

import Arrow from '../../left_arrow.svg' //leave as relative because is not importing it with absolute path
import styles from './styles'

const DropdownList = React.forwardRef(
  (
    {
      contentLinks,
      matchRoute,
      onClick,
      id,
      selectedId,
      locale,
      event,
      session,
    },
    ref,
  ) => (
    <ul
      ref={ref}
      sx={
        selectedId !== id
          ? styles.dropDownContentList
          : {
              ...styles.dropDownContentList,
              ...styles.dropDownContentList.active,
            }
      }
      id={id}
    >
      {contentLinks.map(({ text, link }, index) => (
        <li
          key={`drop-content-${index}`}
          sx={matchRoute(link) && { ...styles.match }}
          onClick={(e) => {
            onClick(e, link, false)
            const trackInfo = getTrackingInfo('', link)
            trackInfo &&
              event(
                trackInfo.csmEventName,
                TRACKING_ACTION.clicked_cta,
                TRACKING_CATEGORY.menu_bar,
                trackInfo.attribute,
                locale,
                session?.user_number,
              )
          }}
        >
          {text}
          <Arrow />
        </li>
      ))}
    </ul>
  ),
)
export default DropdownList
