export const TRACKING_EVENT_NAME = {
  home_click: 'Clicks home icon',
  menu_bar: 'Menu bar',
  potm_click: 'User lands on POTM home page',
  cc_click: 'User clicks Coaching Corner launch button',
  cl_click: 'Content Library explore button clicked',
  hc_desktop_click: 'Header challenge –button clicked on desktop',
  hc_mobile_click: 'Header challenge –button clicked on mobile',
  fom_click: 'Find out more –learn button clicked',
  visit_aia: 'Clicks visit AIA CTA',
  video_play: 'Play of video content',
  video_one_quarter_watched: 'Video content watched 25%',
  video_half_watched: 'Video content watched 50%',
  video_three_quarter_watched: 'Video content watched 75%',
  video_full_watched: 'Video content watched 100%',
  navigation_click: 'navigation_menu_click',
  banner_impression: 'banner_impression',
  banner_click: 'banner_click',
  external_link_click: 'external_link_click',
  language_click: 'language_button_click',
  vote_button: 'User clicks any vote button',
  view_stats_button: 'User clicks any view stats button',
  submit_button: 'User clicks Submit button',
  product_banner: 'User clicks product banner',
  facebook_icon: 'User clicks Facebook icon',
  instagram_icon: 'User clicks Instagram icon',
  twitter_icon: 'User clicks Twitter icon',
  whatsapp_icon: 'User clicks Whatsapp icon',
  view_stats: 'potm_view_stats',
  survey_initiation: 'potm_survey_initiation',
  survey_submission: 'potm_survey_submission',
  survey_error: 'potm_survey_error',
  social_interaction: 'social_interaction',
  kc_click: 'knowledge_centre',
  kc_click_on_the_pitch: 'knowledge_centre_on_the_pitch',
  kc_click_off_the_pitch: 'knowledge_centre_off_the_pitch',
  ask_spurs: 'ask_spurs',
  camp_album: 'camp_album',
  default_kr: 'Unknown name',
}

export const TRACKING_ACTION = {
  clicked_cta: 'Clicked CTA',
  visit_aia_cta: 'Visit AIA CTA clicked',
  video_play: 'Video play',
  video_one_quarter_watched: 'Video view 25%',
  video_three_quarter_watched: 'Video view 75%',
  video_view: 'Video view',
}
export const TRACKING_CATEGORY = {
  homepage: 'Homepage',
  menu_bar: 'Menu bar',
  fom_page: 'AIA info page',
  media_library: 'Media library page',
  onebillion_page: 'AIA one billion page',
  potm_vote_page: 'POTM vote page',
  potm_entry_page: 'POTM entry page',
  potm_survey_page: 'POTM survey page',
  potm_submission_page: 'POTM submission page',
}
export const TRACKING_ATTRIBUTE = {
  menu_bar: 'Menu bar',
  home: 'Home',
  potm: 'POTM',
  coaching_corner: 'Coaching Corner',
  content_library: 'Content Library',
  header_challenge_mobile: 'Header challenge – Mobile',
  header_challenge_desktop: 'Header challenge – Desktop',
  aia_info: 'AIA info',
  aia_one_billion: 'AIA One billion',
  visit_aia: 'Visit AIA',
  vote: 'Vote',
  view_stats: 'View stats',
  entry_complete: 'Entry complete',
  survey_complete: 'Survey complete',
  product_banner: 'Product banner',
  facebook_icon: 'Facebook icon',
  instagram_icon: 'Instagram icon',
  twitter_icon: 'Twitter icon',
  whatsapp_icon: 'Whatsapp icon',
  knowledge_centre: 'Knowledge Centre',
  kc_on_the_pitch: 'On The Pitch',
  kc_off_the_pitch: 'Off The Pitch',
  ask_spurs: 'Ask Spurs',
  camp_album: 'Camp Album',
  default_kr: 'Unknown attribute',
}

export const PATHS_CATEGORY = {
  '/survey': TRACKING_CATEGORY.potm_survey_page,
  '/contact': TRACKING_CATEGORY.potm_entry_page,
  '/submission': TRACKING_CATEGORY.potm_submission_page,
}

export const EVENT_TYPES = {
  banner: {
    csmEventName: TRACKING_EVENT_NAME.product_banner,
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.potm_submission_page,
    attribute: TRACKING_ATTRIBUTE.product_banner,
  },
  listNavBar: {
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.menu_bar,
    adobeEventName: TRACKING_EVENT_NAME.navigation_click,
  },
  mobileNav: {
    csmEventName: TRACKING_EVENT_NAME.menu_bar,
    action: TRACKING_ACTION.clicked_cta,
  },
  navBar: {
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.menu_bar,
    adobeEventName: TRACKING_EVENT_NAME.navigation_click,
  },
  videoPlay: {
    csmEventName: TRACKING_EVENT_NAME.video_play,
    action: TRACKING_ACTION.video_play,
  },
  home: {
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.homepage,
    adobeEventName: TRACKING_EVENT_NAME.external_link_click,
  },
  cards: {
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.homepage,
    adobeEventName: TRACKING_EVENT_NAME.navigation_click,
  },
  partnershipVideoPlay: {
    csmEventName: TRACKING_EVENT_NAME.video_play,
    action: TRACKING_ACTION.video_play,
    category: TRACKING_CATEGORY.fom_page,
  },
  partnershipVideoView: {
    action: TRACKING_ACTION.video_view,
    category: TRACKING_CATEGORY.fom_page,
  },
  partnershipVisitAIA: {
    csmEventName: TRACKING_EVENT_NAME.visit_aia,
    action: TRACKING_ACTION.visit_aia_cta,
    category: TRACKING_CATEGORY.fom_page,
    attribute: TRACKING_ATTRIBUTE.visit_aia,
  },
  potmVote: {
    csmEventName: TRACKING_EVENT_NAME.vote_button,
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.potm_vote_page,
    attribute: TRACKING_ATTRIBUTE.vote,
  },
  potmContact: {
    csmEventName: TRACKING_EVENT_NAME.submit_button,
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.potm_entry_page,
    attribute: TRACKING_ATTRIBUTE.entry_complete,
    adobeEventName: TRACKING_EVENT_NAME.survey_error,
    formType: 'contact',
    formName: 'Contact',
  },
  potmSurvey: {
    csmEventName: TRACKING_EVENT_NAME.submit_button,
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.potm_survey_page,
    attribute: TRACKING_ATTRIBUTE.survey_complete,
    adobeEventName: TRACKING_EVENT_NAME.survey_submission,
  },
  potmViewStats: {
    csmEventName: TRACKING_EVENT_NAME.view_stats_button,
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.potm_vote_page,
    attribute: TRACKING_ATTRIBUTE.view_stats,
    adobeEventName: TRACKING_EVENT_NAME.view_stats,
  },
  potmSocialLinks: {
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.potm_submission_page,
    adobeEventName: TRACKING_EVENT_NAME.social_interaction,
    socialAction: 'Follow',
  },
  koreaNavBar: {
    csmEventName: TRACKING_EVENT_NAME.default_kr,
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.menu_bar,
    attribute: TRACKING_ATTRIBUTE.default_kr,
  },
  koreaHome: {
    csmEventName: TRACKING_EVENT_NAME.default_kr,
    action: TRACKING_ACTION.clicked_cta,
    category: TRACKING_CATEGORY.homepage,
    attribute: TRACKING_ATTRIBUTE.default_kr,
  },
  koreaVideoPlay: {
    csmEventName: TRACKING_EVENT_NAME.video_play,
    action: TRACKING_ACTION.video_play,
    category: TRACKING_CATEGORY.media_library,
    attribute: TRACKING_ATTRIBUTE.default_kr,
  },
  koreaOneBillionVideoPlay: {
    csmEventName: TRACKING_EVENT_NAME.video_play,
    action: TRACKING_ACTION.video_play,
    category: TRACKING_CATEGORY.onebillion_page,
    attribute: TRACKING_ATTRIBUTE.default_kr,
  },
  koreaOneBillionVideoView: {
    csmEventName: TRACKING_EVENT_NAME.default_kr,
    action: TRACKING_ACTION.video_view,
    category: TRACKING_CATEGORY.onebillion_page,
    attribute: TRACKING_ATTRIBUTE.default_kr,
  },
  koreaOneBillionVisitAIA: {
    csmEventName: TRACKING_EVENT_NAME.visit_aia,
    action: TRACKING_ACTION.visit_aia_cta,
    category: TRACKING_CATEGORY.onebillion_page,
    attribute: TRACKING_ATTRIBUTE.visit_aia,
  },
  koreaPartnershipVideoPlay: {
    csmEventName: TRACKING_EVENT_NAME.video_play,
    action: TRACKING_ACTION.video_play,
    category: TRACKING_CATEGORY.fom_page,
    attribute: TRACKING_ATTRIBUTE.default_kr,
  },
  koreaPartnershipVideoView: {
    csmEventName: TRACKING_EVENT_NAME.default_kr,
    action: TRACKING_ACTION.video_view,
    category: TRACKING_CATEGORY.fom_page,
    attribute: TRACKING_ATTRIBUTE.default_kr,
  },
}

export const EVENT_DETAILS = {
  '/': {
    csmEventName: TRACKING_EVENT_NAME.home_click,
    attribute: TRACKING_ATTRIBUTE.home,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.home,
  },
  '/playerofthemonth': {
    csmEventName: TRACKING_EVENT_NAME.potm_click,
    attribute: TRACKING_ATTRIBUTE.potm,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.potm,
  },
  '/headerchallenge': {
    csmEventName: TRACKING_EVENT_NAME.hc_desktop_click,
    attribute: TRACKING_ATTRIBUTE.header_challenge_desktop,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.header_challenge_desktop,
  },
  '/contentlibrary': {
    csmEventName: TRACKING_EVENT_NAME.cl_click,
    attribute: TRACKING_ATTRIBUTE.content_library,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.content_library,
  },
  '/partnership': {
    csmEventName: TRACKING_EVENT_NAME.fom_click,
    attribute: TRACKING_ATTRIBUTE.aia_info,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.aia_info,
  },
  '/coachingcentre': {
    csmEventName: TRACKING_EVENT_NAME.cc_click,
    attribute: TRACKING_ATTRIBUTE.coaching_corner,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.coaching_corner,
  },
  '/coachingcentre/knowledgecentre': {
    csmEventName: TRACKING_EVENT_NAME.kc_click,
    attribute: TRACKING_ATTRIBUTE.knowledge_centre,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.knowledge_centre,
  },
  '/coachingcentre/knowledgecentre/onthepitch': {
    csmEventName: TRACKING_EVENT_NAME.kc_click_on_the_pitch,
    attribute: TRACKING_ATTRIBUTE.kc_on_the_pitch,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.kc_on_the_pitch,
  },
  '/coachingcentre/knowledgecentre/offthepitch': {
    csmEventName: TRACKING_EVENT_NAME.kc_click_off_the_pitch,
    attribute: TRACKING_ATTRIBUTE.kc_off_the_pitch,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.kc_off_the_pitch,
  },
  '/coachingcentre/askspurs': {
    csmEventName: TRACKING_EVENT_NAME.ask_spurs,
    attribute: TRACKING_ATTRIBUTE.ask_spurs,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.ask_spurs,
  },
  '/coachingcentre/campalbum': {
    csmEventName: TRACKING_EVENT_NAME.camp_album,
    attribute: TRACKING_ATTRIBUTE.camp_album,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.camp_album,
  },
  mNavFom: {
    category: TRACKING_CATEGORY.fom_page,
    attribute: TRACKING_ATTRIBUTE.aia_one_billion,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.aia_one_billion,
  },
  mNavHome: {
    category: TRACKING_CATEGORY.homepage,
    attribute: TRACKING_ATTRIBUTE.menu_bar,
    navigationLinkNameClicked: TRACKING_ATTRIBUTE.menu_bar,
  },
  facebook: {
    csmEventName: TRACKING_EVENT_NAME.facebook_icon,
    attribute: TRACKING_ATTRIBUTE.facebook_icon,
  },
  instagram: {
    csmEventName: TRACKING_EVENT_NAME.instagram_icon,
    attribute: TRACKING_ATTRIBUTE.instagram_icon,
  },
  twitter: {
    csmEventName: TRACKING_EVENT_NAME.twitter_icon,
    attribute: TRACKING_ATTRIBUTE.twitter_icon,
  },
  whatsapp: {
    csmEventName: TRACKING_EVENT_NAME.whatsapp_icon,
    attribute: TRACKING_ATTRIBUTE.whatsapp_icon,
  },
  oneQuarter: {
    csmEventName: TRACKING_EVENT_NAME.video_one_quarter_watched,
  },
  half: {
    csmEventName: TRACKING_EVENT_NAME.video_half_watched,
  },
  threeQuarter: {
    csmEventName: TRACKING_EVENT_NAME.video_three_quarter_watched,
  },
  full: {
    csmEventName: TRACKING_EVENT_NAME.video_full_watched,
  },
}
