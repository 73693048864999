import addMasterMapping from './addMasterMapping'
import isBrowser from './isBrowser'
import redirect from './redirect'
import requester from './requester'
import locale from './locale'
import parseUrl from './parseUrl'
import getQueryString from './getQueryString'
import getUTMParamsFromQuery from './getUTMParamsFromQuery'
import getUTMParamsFromUrl from './getUTMParamsFromUrl'
import getTrackingInfo from './getTrackingInfo'
import isMobile from './isMobile'
import isAuthProtected from './isAuthProtected'
import downloadImage from './downloadImage'
import bulkDownloadImages from './bulkDownloadImages'
import addRegionsDependentQuestion from './addRegionsDependentQuestion'
import { getPublicIP, getGeoIpData } from './network'

export default {
  addMasterMapping,
  isBrowser,
  redirect,
  requester,
  locale,
  parseUrl,
  getQueryString,
  getUTMParamsFromQuery,
  getUTMParamsFromUrl,
  isMobile,
  isAuthProtected,
  getPublicIP,
  getGeoIpData,
  getTrackingInfo,
  downloadImage,
  bulkDownloadImages,
  addRegionsDependentQuestion,
}
