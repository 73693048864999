/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useRouter } from 'next/router'

import Box from '/components/Commons/Box'
import ContentBox from '/components/Commons/ContentBox'
import { TRACKING_EVENT_NAME } from '/constants/siteTagging'
import tracking from '/lib/tracking'
import getTrackingInfo from '/utils/getTrackingInfo'

import styles from './styles'

const HomeComponent = ({ contentHub }) => {
  const { sendCSMEvent, sendAdobeEvent } = tracking()
  const router = useRouter()
  const locale = router?.query?.locale
  const defaultImage = 'static/player-of-the-month.png'

  const onTrackEvent = (entry) => {
    const trackInfo = getTrackingInfo('home', entry?.cta?.action)
    sendCSMEvent(trackInfo, locale)

    const isHeaderChallenge = entry?.cta?.action === '/headerchallenge'
    const isCoachingCorner = entry?.cta?.action === '/coachingcorner'

    if (isHeaderChallenge || isCoachingCorner) {
      sendAdobeEvent(
        {
          adobeEventName: TRACKING_EVENT_NAME.external_link_click,
          buttonName: trackInfo.attribute,
          destinationUrl: `${window.location.host}/${locale}${entry?.cta.action}`,
        },
        locale,
      )
    } else {
      sendAdobeEvent(
        {
          adobeEventName: TRACKING_EVENT_NAME.navigation_click,
          navigationLinkNameClicked: trackInfo.attribute,
          navigationLocation: `${locale}${entry?.cta?.action}`,
        },
        locale,
      )
    }
  }

  return (
    <Box sx={styles.contentsBox}>
      {contentHub?.menu?.entries
        ?.filter(
          (i) => i.text !== '' && i.text !== undefined && i.hide !== true,
        )
        ?.map((entry, index) => (
          <ContentBox
            boxStyle={
              entry?.id === 1 ? 'mainContentLinksBox' : 'contentLinksBox'
            }
            image={entry?.image || defaultImage}
            overlay={entry?.id === 1 ? undefined : 'tmBlue'}
            title={entry.text}
            subtitle={entry.subtext}
            cta={entry.cta}
            key={`${index}${entry.cta.label}`}
            onClickContent={() => onTrackEvent(entry)}
          />
        ))}
    </Box>
  )
}

export default HomeComponent
