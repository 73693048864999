import config from '/lib/config'

const blacklist = config.MASTER_MAPPING_BLACKLIST_WHEN_FALSE || []

const findQuestion = (questions, currentkey) => {
  for (const q of questions) {
    if (q.name === currentkey) return q
    else if (q.dependentQuestions) {
      const dp = findQuestion(
        q.dependentQuestions.map((dp) => dp.question),
        currentkey,
      )
      if (dp) return dp
    }
  }
}

export default function addMasterMapping(data, questions) {
  return Object.entries(data).reduce((acc, [currentkey, currentValue]) => {
    const question = findQuestion(questions, currentkey)

    if (blacklist.includes(question?.masterName) && currentValue !== true) {
      return acc
    }

    if (question?.masterName !== undefined) {
      return {
        ...acc,
        [question.masterName]: currentValue,
      }
    }
    return acc
  }, data)
}
