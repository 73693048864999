/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx, Box } from 'theme-ui'

import HomeCard from '/components/Korea/HomeCard'
import RowAligned from '/components/Korea/HomeCard/RowAligned'
import PotmCardDesktop from '/components/Korea/HomeCard/PotmCardDesktop'
import useResponsive from '/hooks/useResponsive'
import tracking from '/lib/tracking'
import getTrackingInfo from '/utils/getTrackingInfo'

import styles from './styles'

const KoreaHomeComponent = (props) => {
  const { sendCSMEvent } = tracking()
  const { layout, locale } = props
  const { isMobile } = useResponsive(props.isMobile)

  const onTrackEvent = (entry) =>
    sendCSMEvent(getTrackingInfo('koreaHome', entry?.cta?.action), locale)

  // * This is to separate the POTM picture and title on desktop because of the different structure with mobile
  const title = layout.menu.entries.find((e) => e.text && !e.images)
  const image = layout.menu.entries.find((e) => e.images && !e.text)

  const visibleEntries = layout.menu.entries.filter((e) => !e.hide)

  const desktopEntries = visibleEntries.reduce(
    (acc, entry, index) => {
      if (
        (entry.images && entry.text && !acc.skipIndex) ||
        entry.homePageProperty?.split(',').includes('fullWidth')
      ) {
        if (
          entry.homePageProperty?.split(',').includes('fullWidth') ||
          entry.homePageProperty?.split(',').includes('rightAligned') ||
          entry.isCarousel ||
          entry.containsSubImages
        ) {
          acc.elements.push(
            <HomeCard
              locale={locale}
              key={index}
              {...entry}
              onClickContent={() => onTrackEvent(entry)}
              isMobile={isMobile}
            />,
          )
        } else {
          // * This is to display in the same row the AR filter and AIA coaching corner
          if (
            visibleEntries[index + 1].homePageProperty
              ?.split(',')
              .includes('sameRow')
          ) {
            acc.elements.push(
              <RowAligned
                key={index}
                entryLeft={entry}
                locale={locale}
                entryRight={visibleEntries[index + 1]}
                onClickContent={() => onTrackEvent(entry)}
                isMobile={isMobile}
              />,
            )
            return { elements: acc.elements, skipIndex: true }
          } else {
            acc.elements.push(
              <RowAligned
                key={index}
                entryLeft={entry}
                locale={locale}
                onClickContent={() => onTrackEvent(entry)}
                isMobile={isMobile}
              />,
            )
            return { elements: acc.elements, skipIndex: false }
          }
        }
      }
      return { elements: acc.elements, skipIndex: false }
    },
    { elements: [], skipIndex: false },
  )

  return (
    <Box sx={styles.mainContainer}>
      {isMobile ? (
        <Box sx={styles.mobileFirstChild}>
          {visibleEntries.map((entry, index) => (
            <HomeCard
              locale={locale}
              key={index}
              {...entry}
              onClickContent={() => onTrackEvent(entry)}
              isMobile={isMobile}
            />
          ))}
        </Box>
      ) : (
        <React.Fragment>
          {title && image && (
            <PotmCardDesktop locale={locale} entryTitle={title} image={image} />
          )}
          {desktopEntries.elements}
        </React.Fragment>
      )}
    </Box>
  )
}

export default KoreaHomeComponent
