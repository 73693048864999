import { EVENT_DETAILS, EVENT_TYPES } from '/constants/siteTagging'

/**
 * Gets the tracking data sent when calling sendCMSEvent or sendAdobeEvent.
 *
 * @param {string} type Type of the event to get the values to track to get the general data.
 * @param {string} details Specific details of the interaction to add more data (card, nav link,
 * etc.). This allows for more flexibility in the tracking data within expected UI elements.
 * @param {any} options Extra params to either complete the values to track or to overwrite
 * specific ones. This allows tracking values that vary depending on Contentful.
 */
export const getTrackingInfo = (type, detail, options) => {
  return {
    ...EVENT_TYPES[type],
    ...EVENT_DETAILS[detail],
    ...options,
  }
}

export default getTrackingInfo
