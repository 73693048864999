/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Heading } from 'theme-ui'
import { useRouter } from 'next/router'
import tracking from '/lib/tracking'
import { TRACKING_EVENT_NAME } from '/constants/siteTagging'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons'
import styles from './styles'
import config from '/lib/config'
import { useEffect, useState } from 'react'

export const LanguageSwitch = ({ isMobile, languageSwitch }) => {
  const router = useRouter()
  const defaultLocale = router?.query?.locale
  const locales = config.LOCALESConfig
  const textLocales = languageSwitch?.locales.filter((l) => l.text !== '')
  const { sendAdobeEvent } = tracking()

  const [localeSelected, setLocaleSelected] = useState(defaultLocale)
  const [openList, setOpenList] = useState(false)

  useEffect(() => setLocaleSelected(defaultLocale), [defaultLocale])

  const localesUnSelected = locales.filter((locale) => {
    if (localeSelected === 'zh-hk')
      return locale !== localeSelected && locale !== 'en'
    return locale !== localeSelected
  })

  const getArrowIcon = () => {
    if (isMobile) return openList ? faAngleUp : faAngleDown
    return openList ? faAngleLeft : faAngleRight
  }

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Box onClick={() => setOpenList(!openList)} sx={styles.localeContainer}>
          <Heading
            as="h4"
            sx={{ ...styles.localeTitle, variant: `text.localeTitle` }}
          >
            {textLocales?.find((l) => l.link === localeSelected)?.text}
          </Heading>
          <Box sx={styles.arrowContainer}>
            <FontAwesomeIcon icon={getArrowIcon()} size="lg" color="white" />
          </Box>
        </Box>
        {openList && (
          <ul sx={styles.localeOptionsContainer}>
            {textLocales
              ?.filter((l) => localesUnSelected.includes(l.link))
              .map((locale, index) => (
                <li
                  key={`${index}${locale.link}`}
                  onClick={() => {
                    sendAdobeEvent(
                      {
                        adobeEventName: TRACKING_EVENT_NAME.language_click,
                        buttonName: locale.link,
                      },
                      defaultLocale,
                    )
                    router.push({
                      pathname: router.pathname,
                      query: { locale: locale.link },
                    })
                  }}
                  sx={{ ...styles.localeTitle, variant: `text.localeTitle` }}
                >
                  <Heading
                    as="h4"
                    sx={
                      locale?.text === '中文'
                        ? {
                            ...styles.chinesseFormat,
                            variant: `text.localeTitle`,
                          }
                        : { ...styles.localeTitle, variant: `text.localeTitle` }
                    }
                  >
                    {locale.text}
                  </Heading>
                </li>
              ))}
          </ul>
        )}
      </Box>
    </Box>
  )
}
