/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useRouter } from 'next/router'
import { jsx, Box, Image, Button, Heading, Text } from 'theme-ui'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import tracking from '/lib/tracking'
import { TRACKING_EVENT_NAME } from '/constants/siteTagging'
import DotButton from '/components/Commons/DotButton'
import styles from './styles'
import useResponsive from '/hooks/useResponsive'

const Carousel = React.forwardRef(
  (
    {
      slides = [],
      options = { loop: false },
      imageStyle,
      titleStyle,
      subtitleStyle,
      buttonStyle,
      ...props
    },
    ref,
  ) => {
    const { sendAdobeEvent } = tracking()
    const router = useRouter()
    const locale = router?.query?.locale
    const { isMobile } = useResponsive(props.isMobile)

    const autoplay = useRef(
      Autoplay(
        { delay: 5000, stopOnInteraction: false },
        (emblaRoot) => emblaRoot.parentElement,
      ),
    )

    const [viewportRef, embla] = useEmblaCarousel(options, [autoplay.current])
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [firedIndexes, setFiredIndexes] = useState([])
    const [scrollSnaps, setScrollSnaps] = useState([])

    const scrollTo = useCallback(
      (index) => {
        if (!embla) return
        embla && embla.scrollTo(index)
        autoplay.current.reset()
      },
      [embla],
    )

    const onSelect = useCallback(() => {
      if (!embla) return
      setSelectedIndex(embla.selectedScrollSnap())
    }, [embla, setSelectedIndex])

    useEffect(() => {
      if (!firedIndexes.includes(selectedIndex)) {
        sendAdobeEvent(
          {
            adobeEventName: TRACKING_EVENT_NAME.banner_impression,
            bannerType: 'carrousel',
            bannerName: `${slides[selectedIndex]?.title} ${slides[selectedIndex]?.subtitle}`,
            promoName: '',
          },
          locale,
        )
        setFiredIndexes([...firedIndexes, selectedIndex])
      }
    }, [selectedIndex])

    useEffect(() => {
      if (!embla) return
      onSelect()
      setScrollSnaps(embla.scrollSnapList())
      embla.on('select', onSelect)
    }, [embla, setScrollSnaps, onSelect])

    return (
      <Box ref={ref} sx={styles.container}>
        <Box ref={viewportRef} sx={styles.viewport}>
          <Box sx={styles.innerBox}>
            {slides?.map((slide, index) => (
              <Box key={index} sx={styles.imageContainer}>
                <Box
                  sx={{
                    ...styles.textContainer,
                    variant: `box.carouselTextContainer`,
                  }}
                >
                  {slide.title && (
                    <>
                      <Text
                        as="h1"
                        sx={{
                          ...styles.title,
                          variant: `text.${titleStyle}`,
                        }}
                      >
                        {slide.title}
                      </Text>
                      <Heading
                        as="h1"
                        sx={{
                          ...styles.subTitle,
                          variant: `text.${subtitleStyle}`,
                          fontSize:
                            isMobile && locale !== 'en'
                              ? '2.7rem'
                              : ['3rem', '4.5rem'],
                        }}
                      >
                        {slide?.subtitle}{' '}
                      </Heading>
                    </>
                  )}
                  {slide.cta?.label && (
                    <a
                      href={`/${locale}${slide?.cta.action}`}
                      rel="noreferrer"
                      onClick={() =>
                        sendAdobeEvent(
                          {
                            adobeEventName: TRACKING_EVENT_NAME.banner_click,
                            bannerName: `${slide.title} ${slide.subtitle}`,
                            bannerType: 'carrousel',
                            promoName: '',
                            ctaText: slide?.cta?.label,
                            destinationUrl: `${window.location.host}/${locale}${slide?.cta.action}`,
                          },
                          locale,
                        )
                      }
                    >
                      <Button
                        sx={{
                          ...styles.button,
                          variant: `buttons.${buttonStyle}`,
                        }}
                      >
                        {slide?.cta?.label}
                      </Button>
                    </a>
                  )}
                </Box>
                <Image
                  sx={{ ...styles.image, variant: `images.${imageStyle}` }}
                  src={slide.image}
                  alt={slide.title}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={styles.dotContainer}>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              disabled={index !== selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </Box>
      </Box>
    )
  },
)

export default Carousel
