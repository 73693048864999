import { v4 as uuidv4 } from 'uuid'
import API from '/repository/repoAPI'

const tracking = () => {
  let userId = null

  if (typeof window !== 'undefined') {
    const localIdentifier = 'user_analytics_id'
    userId = localStorage.getItem(localIdentifier)
    if (!userId) {
      userId = uuidv4()
      localStorage.setItem(localIdentifier, userId)
    }
  }

  const sendCSMEvent = (
    { csmEventName, action, category, attribute },
    locale,
  ) => {
    const body = {
      userId,
      eventName: csmEventName,
      action,
      category,
      attribute,
      locale,
    }
    API.sendTracking('')({ body })
  }

  const sendAdobeEvent = (props, locale) => {
    const csmEventProps = ['csmEventName', 'action', 'category', 'attribute']
    const { adobeEventName, ...payload } = props
    csmEventProps.forEach((prop) => delete payload[prop])

    if (window?._satellite !== undefined) {
      window._satellite.track(adobeEventName, {
        ...payload,
        language: locale,
        websiteCountry: locale,
      })
    }
  }

  return {
    sendCSMEvent,
    sendAdobeEvent,
  }
}

export default tracking
